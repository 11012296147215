<template>
<loader v-if="showloader==true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-450px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_maj_module"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table
            class="table table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-uppercase"
              >
                <th>#</th>
                <th>Nom <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i></th>
                <th>date <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>
                <th>Thematique</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td v-if="item.thematiques.length > 0">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_ListeThematiques"
                    @click="AffichelisteThematiques(item.thematiques)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    <i class="fa fa-eye" aria-hidden="true" style="font-weight: bold; font-size: 18px;"></i>
                  </span>
                </td>
                <td v-else>&nbsp;</td>
                <td class="odd" v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="end">
                  <!-- <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_maj_module"
                    ></i
                    >Edit</span
                  > -->
                  <a
                    href="#"
                    @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_maj_module"
                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
           <div class="row" style="margin-right:0">
              <div class="col-sm-2 col-md-2 d-flex align-items-center justify-content-center justify-content-md-start">
                  <label
            ><select
              class="form-select form-select-sm form-select-solid select_page"
              @change="searchdata"
              v-model="limit"
              style="cursor:pointer"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="100000">All</option>
            </select></label
          >
              </div>
              <div 
              class="col-sm-10 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                  <paginate
                      :page-count="totalpage"
                      :page-range="3"
                      :margin-pages="2"
                      :click-handler="clickCallback"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :container-class="'pagination'"
                      :page-class="'page-item'"
                  >
                  </paginate>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- *****************modal ajouter module***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_maj_module">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ajouter Module</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="isActive" class="form-label required"
                  >Status
                </label>
                <select class="form-select m-2" v-model="add_data.isActive">
                  <option value="1">Activer</option>
                  <option value="0">Desactiver</option>
                </select>
              </div>
            </div>
            <div class="row">
              &nbsp;
            </div>
            <hr>
            <div class="row">
              <label class="form-label">Image fond Module Slider :</label>
              <input class="custom-file-input" type="file" @change="uploadFile()" ref="file">
             
            </div>
            <div v-if="imageError" class="imageError" style="color: red;">
              {{imageError}}
            </div>
            <div class="preview">
              <p></p>
            </div>
            <div class="row" style="margin-top:1%">
             <img v-if="fileImg" :src="pathImg+'/'+fileImg" style="width:50%;"> 
             
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="abonnement" class="form-label">Thematiques </label>
                <Multiselect
                  v-model="selectThematique"
                  mode="tags"
                  :object="true"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="AllThematiques"
                  label="name"
                  trackBy="name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                  <label for="description" class="form-label">Description </label>
                  <editor
                    v-model="add_data.description"
                    initialValue=""
                    api-key='v9nz9dfppblz33d6xgxd6dz9tc6ulwqxsqhflxfcj4s2k8hs'
                    :init="{
                      height: 150,
                      //plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable'
                      plugins: 'advlist lists emoticons table',
                      menubar: 'file edit view insert format tools table tc help',
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                      
                    }"
                  >
              </editor>
                 <!-- <textarea
                      v-model="add_data.description"
                      class="form-control form-control-solid mb-8"
                      rows="6"
                  >                    
                  </textarea>
                -->
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()" :disabled="isActive">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- *****************modal Liste Thematiques***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_ListeThematiques">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Module</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataThematique()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Nom</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, index) in listeThematiques" :key="a.id">
                <td>{{ index + 1 }}</td>
                <td>{{ a.name }}</td>
                <td>
                    <input type="number" v-model="a.order" min="0">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataThematique()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" :disabled="isActive" @click="save_order()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";
import loader from "../utility/loader.vue";
import Paginate from "vuejs-paginate-next";
const MAX_SIZE = 200000;
const MAX_WIDTH = 810;
const MAX_HEIGHT = 496;
const MIN_WIDTH = 600;
const MIN_HEIGHT = 370;
export default {
  components: {
    Multiselect,
    editor: Editor,
    loader,
    Paginate
  },
  data() {
    return {
      data: {},
      add_data: {
        isActive: 1,
        id: 0,
      },
      Images:null,
      formMode: "ajouter",
      selectThematique: [],
      AllThematiques: {},
      listeThematiques: {},    
      imageLoaded:false, 
      pathImg:"",
      fileImg:"",
      size:"",
      height:"",
      width:"",
           
      showloader: false,

      totalpage:0,
      currentpage:1,
      limit:10,
      sum:0,
      offset:0,
      searchValue:"",
      orderStatus: "asc",
      imageError:'',
      isActive: false,

    };
  },
  methods: {
      sort(index) {
        if (this.orderStatus === 'asc') {
            this.orderStatus = 'desc';
            this.data = this.data.sort((a, b) => {
              return b[index].localeCompare(a[index]);
            });
        } else if (this.orderStatus === 'desc') {
            this.orderStatus = 'asc';
            this.data = this.data.sort((a, b) => {
              return a[index].localeCompare(b[index]);
            });
        };
      },
      date_format(value){
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
      },
      save_order(){
          this.showloader = true;
          const data = {};
          data["liste"] = this.listeThematiques;
          data["table"] = "thematiques";

          ApiService.setHeader();
          ApiService.post("tests/order/InsertOrUpdate", data)
            .then((response) => {    
                  this.showloader = false;            
                  this.alertSucess();
            })
            .catch(({ response }) => {
              console.log(response);
            });
      },
      uploadFile() {
        this.imageError = '';
        this.Images = this.$refs.file.files[0]; 
        //console.log(this.Images);
        //console.log(this.Images.size);

        if(this.Images.size > MAX_SIZE) {
          this.imageError = `La taille de l'image (${this.Images.size} Ko) est trop importante (maximum requis est de ${MAX_SIZE} Ko).`;
          this.alerterror();
        }
        let reader = new FileReader();
      
        reader.readAsDataURL(this.Images);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            this.Images.width = img.width;
            this.Images.height = img.height;
            
            if(this.Images.width > MAX_WIDTH || this.Images.width < MIN_WIDTH ) {
              this.imageError = `La taille de l'image (largeur ${this.Images.width}px sur hauteur ${this.Images.height}px) ne correspond pas (maximum requis ${MAX_WIDTH}px * ${MAX_HEIGHT}px et minimum requis ${MIN_WIDTH}px * ${MIN_HEIGHT}px).`;
              this.alerterror();
            }
            else if(this.Images.height > MAX_HEIGHT || this.Images.height < MIN_HEIGHT) {
              this.imageError = `La taille de l'image ( largeur ${this.Images.width}px sur hauteur ${this.Images.height}px ) ne correspond pas (maximum requis ${MAX_WIDTH}px * ${MAX_HEIGHT}px et minimum requis ${MIN_WIDTH}px * ${MIN_HEIGHT}px).`;
              this.alerterror();
            }
            else{
              this.isActive = false;
            }
           
          }
          img.src = evt.target.result;
        }

        reader.onerror = evt => {
          console.error(evt);
        }
      },
   
     getListeSequence() {
      ApiService.setHeader();
      ApiService.get("formation/sequence")
        .then((result) => {
          this.listeSequence = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AffichelisteThematiques(thematiques) {
      this.listeThematiques = thematiques;
    },
    getListeModule() {
      this.showloader = true;
      this.offset = this.limit * (this.currentpage-1);
      ApiService.setHeader();
      ApiService.query("formation/module",{
        params:{
            searchValue: this.searchValue,
            limit:this.limit,
            offset:this.offset
        }
      }).then(async(result) => {
          this.data = await result.data.liste;
          this.sum = await result.data.sum;
          this.initpage();
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListeThematique() {
      ApiService.setHeader();
      ApiService.get("formation/thematique")
        .then(async (result) => {
          this.AllThematiques = await result.data;
          this.traitement_thematique();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      this.showloader = true;
      const data_select_tmp = [];
      this.selectThematique.forEach(async (item, index) => {
        await data_select_tmp.push(item.id);
      });

      this.add_data["listeIdThematique"] = data_select_tmp;
      this.add_data["formMode"] = this.formMode;

      this.add_data["formMode"] = this.formMode;
      ApiService.post("formation/module/InsertOrUpdate", this.add_data)
        .then((response) => {
            if(this.Images){
              console.log(this.Images);
                this.submitFile(response.data);
                
            }else{
              this.showloader = false;
                this.alertSucess();
            }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    submitFile(id) {
          const formData = new FormData();
          formData.append('file', this.Images);
          formData.append('id', id);
          const headers = { 'Content-Type': 'multipart/form-data' };
          ApiService.post("formation/module/UploadFile", formData, { headers }).then((res) => {
          res.data.files; // binary representation of the file
            // res.status; // HTTP status
            // this.showloader = false;
           
            this.alertSucess();
          });
    },
    async maj(item, val) {
      this.formMode = val;
      this.selectModule = {};
      this.resetData();

      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;

        const media = data_tmp.media_module[0];
        if(media){
            this.fileImg = await media.path;
           // console.log(this.fileImg);
        }

        this.traitement_select_thematique(item.thematiques);
      } else {
        this.selectThematique = [];
      }
    },
    resetData() {
      this.add_data = {
        isActive: 1,
        id: 0,
        order: 1,
      };
      this.selectThematique = [];
    },
    resetDataThematique() {
      this.listeThematiques = {};
    },
    traitement_select_thematique(data){
      data.forEach(async (item, index) => {
        data[index]["value"] = await item.id;
        this.selectThematique.push(item);
      });
    },
    traitement_thematique() {
      this.AllThematiques.forEach(async (item, index) => {
        this.AllThematiques[index]["value"] = await item.id;
      });
    },
    alertSucess() {
        Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        }).then(function () {
          location.reload();
        });
      },
    alerterror(){
      if(!this.imageError){
        this.isActive = false;
        Swal.fire({
        text: "Format Image Correct!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
      }else{
        this.showloader = false;
        this.isActive = true;
        Swal.fire({
              text: "Veuillez modifier et redimensionner votre image",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Réessayer!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
          });
      }
    },
    async getpathImg(){
        this.pathImg = await process.env.VUE_APP_FILE;
    },
    clickCallback (pageNum){
      this.currentpage = pageNum;
      this.getListeModule();
    },
    async initpage(){
      this.totalpage = await Math.ceil(this.sum / this.limit);
    },
    async searchdata(){
      this.currentpage = await 1;
      this.getListeModule();
    }
  },
  mounted() {
    this.getListeModule();
    this.getListeThematique();
    this.getpathImg(); 
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>