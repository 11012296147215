<template>
  <span class="loader">
    <span class="inner-spinner"></span>
  </span>
</template>
<script>
export default {};
</script>
<style scoped>
.loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid rgb(30, 113, 209);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-left: 30%;
  position: fixed;
  z-index: 999999;
}

.loader::after {
  content: "";
  position: fixed;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 8px solid transparent;
  border-top: 8px solid rgb(109, 120, 133);
  animation: rotate 1.3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
